export function checkExpiresIsNull(val: string | Date | null): boolean {
  if (val) {
    let dt: Date;
    if (typeof val === 'string') {
      dt = new Date(val);
    } else {
      dt = val;
    }
    const year = dt.getFullYear();
    return year < 1000;
  }
  return true;
}

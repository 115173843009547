import { RestApi } from '@nextgis_misc/services/RestApi';
import bbox from '@turf/bbox';
import { http } from '../../app/services/httpService';
import { checkExpiresIsNull } from '../utils/checkExpiresIsNull';

import type { DeepPartial, TileJson } from '@nextgis/utils';
import type { RequestOptions } from '@nextgis_misc/options/RequestOptions';
import type { TileCache } from '../interfaces/TileCache';
import type { SeedingStatus } from '../interfaces/SeedingStatus';
import type { ExportCacheOptions } from '../interfaces/ExportCacheOptions';
import type { IdentifyOptions } from '../interfaces/IdentifyOptions';
import type { RRCacheIdentify } from '../interfaces/RRCacheIdentify';
import type { ApiRequestOptions } from '../interfaces/ApiRequestOptions';
import type { MultiPolygon } from 'geojson';
import type { SeedingTaskType } from '../interfaces/SeedingTask';
import type { FetchSeederOptions } from '../interfaces/FetchSeederOptions';
import { SeederItem } from '../interfaces/SeederItem';

interface ExtUserRequestOptions {
  limit?: number;
  offset?: number;
}

interface ExtUserResp {
  ext_user: string;
  ext_user_id: string;
}

export interface CreateSeederTaskOptions {
  geom?: MultiPolygon | null;
  is_full_extent?: boolean;
  type: SeedingTaskType;
}

export class CacheApi extends RestApi<TileCache> {
  http = http;
  name = 'cache';

  getOne(id: string | number, opt: RequestOptions = {}): Promise<TileCache> {
    return super.getOne(id, opt).then((cache) => this.formatCache(cache));
  }

  create(body: DeepPartial<TileCache>): Promise<TileCache> {
    return super.create(body).then((cache) => this.formatCache(cache));
  }

  update(
    id: string | number,
    body: DeepPartial<TileCache>,
  ): Promise<TileCache> {
    return super.update(id, body).then((cache) => this.formatCache(cache));
  }

  getMany(params: Record<string, any> = {}): Promise<TileCache[]> {
    return super.getMany(params).then((resp) => {
      resp.forEach((x) => this._formateRespDate(x));
      return resp;
    });
  }

  toggleSeeding(id: number | string, status: SeedingStatus): Promise<void> {
    return this.http.post('/api/seeding/' + id, { body: { status } });
  }

  toggleProp(
    id: number | string,
    propName: string,
    status: boolean,
    cache: Partial<TileCache>,
    opt: RequestOptions = {},
  ): Promise<TileCache> {
    return this.http.patch('/api/cache/' + id, {
      body: { ...cache, [propName]: status },
      ...opt,
    });
  }

  fetchSeeder(opt: RequestOptions<FetchSeederOptions>): Promise<SeederItem[]> {
    return this.http.get('/api/seeder', opt);
  }

  startSeeding(id: number | string): Promise<void> {
    return this.toggleSeeding(id, 1);
  }

  pauseSeeding(id: number | string): Promise<void> {
    return this.toggleSeeding(id, 4);
  }

  tileJson(id: string | number, options?: RequestOptions): Promise<TileJson> {
    return this.http.get(`/json/${id}`, options);
  }

  identify(
    id: number,
    options: IdentifyOptions,
    requestOptions?: RequestOptions,
  ): Promise<RRCacheIdentify> {
    const { apikey, ...body } = options;
    return this.http.post(`/identify/${id}?apikey=${apikey}`, {
      body,
      ...requestOptions,
    });
  }

  findExternalUser(
    q: string,
    options: ExtUserRequestOptions = {},
  ): Promise<ExtUserResp[]> {
    return this.http.get('/api/external_user?q=' + q);
  }

  export(id: string | number, body: ExportCacheOptions): Promise<string> {
    return this.http.post('/api/export/' + id, {
      body,
      responseType: 'arraybuffer',
    });
  }

  setScaleStatus(
    scaleId: number,
    status: SeedingStatus,
    opt: RequestOptions = {},
  ) {
    this.http.put('/api/scale/' + scaleId, { body: { status }, ...opt });
  }

  createSeederTask(zoomLevelId: number, body: CreateSeederTaskOptions) {
    return this.http.post('/api/scale/' + zoomLevelId, {
      body,
    });
  }

  deleteSeederTask(id: number): Promise<unknown> {
    return this.http.delete('/api/seeder/' + id);
  }

  formatCache(cache: TileCache): TileCache {
    if (cache.geom) {
      const [_min_x, _min_y, _max_x, _max_y] = bbox(cache.geom);
      Object.assign(cache, { _min_x, _min_y, _max_x, _max_y });
    }
    this._formateRespDate(cache);
    return cache;
  }

  private _formateRespDate(cache: TileCache): void {
    if (cache.options) {
      for (const x of cache.options) {
        if (checkExpiresIsNull(x.expires)) {
          x.expires = '';
        } else if (x.expires) {
          const targetTime = new Date(x.expires);
          x.expires = targetTime;
        }
      }
    }
  }
}

export const cacheApi = new CacheApi();

import Vue from 'vue';
import VueI18n, { LocaleMessages } from 'vue-i18n';
import { createTrans } from '@nextgis_misc/vue/i18n/createTrans';
import defaultSettings from '../settings';

Vue.use(VueI18n);

const { languages, defaultLanguage, fallbackLanguage } = defaultSettings;

const messages = {} as LocaleMessages;
languages.forEach((l) => {
  messages[l.value] = {};
});

const i18n = new VueI18n({
  locale: defaultLanguage, // set locale
  fallbackLocale: fallbackLanguage,
  messages, // set locale messages
  silentTranslationWarn: true,
  silentFallbackWarn: true, // process.env.NODE_ENV === 'production',
});

export const trans = createTrans({
  i18n,
  defaultLanguage: defaultLanguage,
  supportedLanguages: languages.map((x) => x.value),
});

try {
  trans.loadLanguageFile(defaultLanguage).then((locale) => {
    messages[defaultLanguage] = locale.default;
  });
} catch (er) {
  //
}

export { i18n };

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: {
        eager: "",
        value: "true",
        "max-width": _vm.width,
        persistent: _vm.persistent,
      },
      on: {
        input: _vm.change,
        keydown: function ($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
          )
            return null
          return _vm.choose(false)
        },
      },
    },
    [
      _c(
        "v-card",
        { attrs: { tile: "" } },
        [
          Boolean(_vm.title)
            ? _c(
                "v-toolbar",
                { attrs: { color: _vm.color, dense: "", flat: "" } },
                [
                  Boolean(_vm.icon)
                    ? _c("v-icon", { attrs: { left: "" } }, [
                        _vm._v(_vm._s(_vm.icon)),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("v-toolbar-title", {
                    staticClass: "white--text",
                    domProps: { textContent: _vm._s(_vm.title) },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "v-card-text",
            { staticClass: "body-1 text-body-1 py-3" },
            [
              _vm.message
                ? _c("p", { domProps: { innerHTML: _vm._s(_vm.message) } })
                : _vm._e(),
              _vm._v(" "),
              _c("ItemForm", {
                attrs: { fields: _vm.fields },
                model: {
                  value: _vm.item,
                  callback: function ($$v) {
                    _vm.item = $$v
                  },
                  expression: "item",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _vm._v(" "),
              Boolean(_vm.buttonCancelText)
                ? _c(
                    "v-btn",
                    {
                      attrs: {
                        color: _vm.buttonCancelColor,
                        text: _vm.buttonCancelFlat,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.choose(false)
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.buttonCancelText) +
                          "\n        "
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              Boolean(_vm.buttonApplyText)
                ? _c(
                    "v-btn",
                    {
                      attrs: {
                        color: _vm.buttonApplyColor,
                        text: _vm.buttonApplyFlat,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.choose(true)
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.buttonApplyText) +
                          "\n        "
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import { Vue, Component, Watch } from 'vue-property-decorator';
import { PageLoading } from '@nextgis_misc/vuetify/components/PageLoading';
import { userCookie } from '../services/userCookie';
import { appModule } from '../store/app';
import { userModule } from '../store/user';
import { trans } from '../../plugins/i18n';

import type { Route } from 'vue-router';

@Component({
  components: { PageLoading },
})
export default class App extends Vue {
  get loading(): boolean {
    return appModule.loading;
  }

  get locale(): string {
    return userModule.locale;
  }

  @Watch('locale')
  onLocaleChange(): void {
    if (this.locale) {
      trans.changeLanguage(this.locale);
    }
  }

  @Watch('$route')
  changeTitle(to: Route): void {
    const metaTitle = to.meta && to.meta.title;

    const title = this.$t('Providing information to related systems');

    document.title = title || metaTitle || 'Title';
  }

  created(): void {
    const logged = userCookie.getLoginState();
    if (logged) {
      appModule.setLoading(true);
      userModule
        .getInfo()
        .then(() => {
          // logged
        })
        .catch((er) => {
          userModule.logout();
        })
        .finally(() => {
          appModule.setLoading(false);
          // this.$router.push('/');
        });
    }
  }
}

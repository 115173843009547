import RRLogoImg from '../../../images/rr.svg';
import { mdiLogout } from '@mdi/js';
import { confirmDialog } from '@nextgis_misc/vuetify/notice';
import NavTree from '@nextgis_misc/vuetify/components/NavTree/NavTree.vue';
import ProfileMenu from '../../components/ProfileMenu/ProfileMenu.vue';
import { Vue, Component } from 'vue-property-decorator';
import { permissionModule } from '../../store/permission';
import { userModule } from '../../store/user';
import { appModule } from '../../store/app';

@Component({ components: { NavTree, ProfileMenu } })
export default class MainLayout extends Vue {
  icons = {
    logout: mdiLogout,
    logo: RRLogoImg,
  };

  drawer = null;

  get routes() {
    return permissionModule.dynamicRoutes;
  }

  get title(): string {
    return appModule.title;
  }

  get key() {
    return this.$route.path;
  }

  async logout() {
    const ok = await confirmDialog(String(this.$t('confirm_logout')));
    if (ok) {
      await userModule.logout();
      this.$router.push(`/login`);
    }
  }
}

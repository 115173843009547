var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-app",
    { attrs: { id: "app" } },
    [!_vm.loading ? _c("router-view") : _c("PageLoading")],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import { createHttp } from '@nextgis_misc/services/Http';
import { notice } from '@nextgis_misc/vuetify/notice';
import { i18n } from '../../plugins/i18n';
import settings from '../../settings';
import { userModule } from '../store/user';

export const http = createHttp(settings.baseUrl, {
  crossDomain: true,
  onError: (er) => {
    if (er && er.status) {
      if (er.status === 401) {
        userModule.logout();
      } else if (er.status === 403) {
        notice(i18n.t('http_forbidden_error'));
      } else {
        if (er.error) {
          notice(er.error, { color: 'error' });
        }
      }
    }
  },
});

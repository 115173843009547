import { Vue, Component } from 'vue-property-decorator';

import { userModule } from '../../store/user';

@Component({
  components: {},
})
export default class ProfileMenu extends Vue {
  get name(): string {
    return userModule.name;
  }
}

import { Settings } from './app/interfaces/Settings';
import { userCookie } from './app/services/userCookie';

const localSettings = {};
if (process.env.NODE_ENV === 'development') {
  try {
    // localSettings = require('../settings.json');
  } catch {
    //
  }
}

const defaultSettings: Settings = {
  title: 'Providing information to related systems',
  footer: true,
  sidebarLogo: true,
  onPrem: true,
  chunkSize: 24 * 1024 * 1024,

  version: '0.0.0',

  baseUrl: process.env.NODE_ENV === 'development' ? '' : '',
  defaultLanguage: userCookie.getLocale() || 'ru',
  fallbackLanguage: 'en',
  languages: [
    {
      value: 'en',
      label: 'EN',
    },
    {
      value: 'ru',
      label: 'RU',
    },
  ],
};

export default { ...defaultSettings, ...localSettings };

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("v-treeview", {
    attrs: {
      items: _vm.items,
      active: _vm.active,
      open: _vm.open,
      activatable: "",
      "open-on-click": "",
    },
    on: { "update:active": _vm.onActiveItemsUpdated },
    scopedSlots: _vm._u([
      {
        key: "label",
        fn: function ({ item }) {
          return [
            item.to && !item.children
              ? [_vm._v(_vm._s(item.name))]
              : _c("span", [_vm._v(_vm._s(item.name))]),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import { Framework } from 'vuetify';

import ru from 'vuetify/src/locale/ru';
import en from 'vuetify/src/locale/en';
import settings from '../settings';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#3D83C8',
        // accent: '#00b77e',
        // secondary: '#e5eef7',
        // info: '#2196f3',
        // warning: '#ffc107',
        // error: '#ff5252',
        // success: '#4caf50',
      },
    },
    options: {
      customProperties: true,
    },
  },
  breakpoint: {
    mobileBreakpoint: 1180,
  },
  lang: {
    // t: (key, ...params) => String(i18n.t(key, params)),
    locales: { ru, en },
    current: settings.defaultLanguage,
  },
  icons: {
    iconfont: 'mdiSvg',
  },
});

declare module 'vue/types/vue' {
  export interface Vue {
    $vuetify: Framework;
  }
}

import {
  Module,
  getModule,
  MutationAction,
  Action,
  Mutation,
  VuexModule,
} from 'vuex-module-decorators';
import type { Credentials } from '@nextgis_misc/interfaces/Credentials';
import { trans } from '../../plugins/i18n';
import settings from '../../settings';
import { userCookie } from '../services/userCookie';
import { authApi } from '../services/authApi';
import router, { resetRouter } from '../router';
import { permissionModule } from './permission';
import store from '.';

const defaultAvatar = '';
const cookie = userCookie;
const api = authApi;

@Module({ dynamic: true, store, name: 'user' })
export class User extends VuexModule {
  id: number | null = null;
  name = '';
  avatar = defaultAvatar;
  // 3 - oauth2
  loginType: number | null = null;
  role = '';
  locale = userCookie.getLocale() || 'en';
  defaultLocale = 'en';
  logged = userCookie.getLoginState();

  @MutationAction({ mutate: ['logged'] })
  login({ username, password }: Credentials): Promise<{ logged: boolean }> {
    return api
      .login({ username: username.trim(), password: password })
      .then((response) => {
        cookie.setLoginState(true);
        return { logged: true };
      });
  }

  @Action
  loginOAuth2(code: string): Promise<void> {
    return api.loginOAuth2(code).then((response) => {
      return this.getInfo();
    });
  }

  @Action
  getInfo(): Promise<void> {
    return api.getInfo().then((response) => {
      const { name, avatar, group_type, locale, id, login_type } = response;
      cookie.setLoginState(true);

      const isAdmin = group_type === 1;
      const role = isAdmin ? 'admin' : 'user';
      let newLoc: string = locale;
      if (newLoc.length === 0) {
        const userLang = cookie.getLocale() || settings.defaultLanguage;
        newLoc = userLang;
      }
      trans
        .changeLanguage(newLoc)
        .then(() => {
          cookie.setLocale(newLoc);
        })
        .catch(() => {
          cookie.setLocale(settings.defaultLanguage);
        });

      resetRouter();
      // Generate dynamic accessible routes based on roles
      permissionModule.generateRoutes([role]);
      // Add generated routes
      permissionModule.dynamicRoutes.forEach((x) => router.addRoute(x));

      this.SET_LOGGED(true);
      this.SET_ROLE(role);
      this.SET_NAME(name);
      this.SET_AVATAR(avatar);
      this.SET_LOCALE(locale);
      this.SET_LOGIN_TYPE(login_type);
      this.SET_ID(id);
    });
  }

  @Action
  async logout(): Promise<void> {
    try {
      await api.logout();
      this.SET_LOGGED(false);
      this.SET_ROLE('');
      this.SET_NAME('');
      this.SET_AVATAR('');
      this.SET_LOGIN_TYPE(null);
      this.SET_ID(null);
      resetRouter();
      cookie.removeLoginState();
      router.push('/login');
    } catch {
      // ignore
    }
  }

  @Mutation
  private SET_LOGGED(logged: boolean) {
    this.logged = logged;
  }

  @Mutation
  private SET_NAME(name: string) {
    this.name = name;
  }

  @Mutation
  private SET_AVATAR(avatar: string) {
    this.avatar = avatar;
  }

  @Mutation
  private SET_ROLE(role: string) {
    this.role = role;
  }

  @Mutation
  private SET_LOCALE(locale: string) {
    this.locale = locale;
  }

  @Mutation
  private SET_ID(id: number | null) {
    this.id = id;
  }

  @Mutation
  private SET_LOGIN_TYPE(loginType: number | null) {
    this.loginType = loginType;
  }
}

export const userModule = getModule(User);

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-menu",
    {
      attrs: { bottom: "", "min-width": "200px", rounded: "", "offset-y": "" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on }) {
            return [
              _c(
                "v-btn",
                _vm._g({ attrs: { icon: "", "x-large": "" } }, on),
                [
                  _c(
                    "v-avatar",
                    { attrs: { color: "secondary" } },
                    [
                      _c("v-icon", { attrs: { dark: "" } }, [
                        _vm._v(" mdi-account-circle "),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          },
        },
      ]),
    },
    [
      _vm._v(" "),
      _c(
        "v-card",
        [
          _c("v-list-item-content", { staticClass: "justify-center" }, [
            _c(
              "div",
              { staticClass: "mx-auto text-center" },
              [
                _c("h3", [_vm._v(_vm._s(_vm.name))]),
                _vm._v(" "),
                _c("v-divider", { staticClass: "my-3" }),
                _vm._v(" "),
                _c(
                  "v-btn",
                  {
                    attrs: {
                      depressed: "",
                      rounded: "",
                      text: "",
                      to: { name: "profile-settings" },
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("edit_profile")))]
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import { userModule } from './store/user';
import { cacheRoutes } from '../cache/cacheRoutes';
import { userRouters } from '../users/usersRoutes';
import { settingsRoutes } from '../settings/settingsRoutes';

Vue.use(VueRouter);

/*
  Note: sub-menu only appear when children.length>=1
*/

/*
  name:'router-name'             the name field is required when using <keep-alive>, it should also match its component's name property
  redirect:                      TODO: if set to 'noredirect', no redirect action will be trigger when clicking the breadcrumb
  meta: {
    roles: ['admin', 'editor']   will control the page roles (allow setting multiple roles)
    title: 'title'               the name showed in subMenu and breadcrumb (recommend set)
    icon: 'svg-name'             TODO: the icon showed in the sidebar
    hidden: true                 TODO: if true, this route will not show in the sidebar (default is false)
    alwaysShow: true             TODO: if true, will always show the root menu (default is false)
                                 if false, hide the root menu when has less or equal than one children route
    breadcrumb: false            TODO: if false, the item will be hidden in breadcrumb (default is true)
    noCache: true                TODO: if true, the page will not be cached (default is false)
    affix: true                  TODO: if true, the tag will affix in the tags-view
    activeMenu: '/example/list'  TODO: if set path, the sidebar will highlight the path you set
  }
*/

export const pageRoutes: RouteConfig[] = [
  ...settingsRoutes,
  ...cacheRoutes,
  ...userRouters,
];

export const constantRoutes: RouteConfig[] = [
  {
    path: '/login',
    component: () => import('./views/Login/Login.vue'),
    meta: { anon: true },
  },
  {
    path: '/oauth2/callback',
    component: () => import('./views/Oauth2/Callback.vue'),
    meta: { anon: true },
  },
  {
    path: '/404',
    component: () => import('./views/404.vue'),
    meta: { anon: true },
  },
  // {
  //   path: '/',
  //   component: () => import('./layouts/Main/MainLayout.vue'),
  //   children: [...pageRoutes],
  // },
];

export const asyncRoutes: RouteConfig[] = [
  ...pageRoutes,
  { path: '*', redirect: '/404' },
];

const createRouter = () =>
  new VueRouter({
    mode: 'history', // require service support
    scrollBehavior: () => ({ x: 0, y: 0 }),
    routes: [...constantRoutes], // https://router.vuejs.org/api/#router-addroutes
  });

const router = createRouter();

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
  const newRouter = createRouter();
  (router as any).matcher = (newRouter as any).matcher; // reset router
}

router.beforeEach((to, from, next) => {
  const user = userModule.logged;
  const anon = to.meta && to.meta.anon;
  if (!user && !anon) {
    if (to.path !== '/login') {
      return next({
        path: '/login',
        query: {
          next: to.fullPath,
        },
      });
    }
  }
  next();
});

export default router;

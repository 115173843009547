import { render, staticRenderFns } from "./ItemForm.vue?vue&type=template&id=88e4e232&scoped=true&"
import script from "./ItemForm.ts?vue&type=script&lang=ts&"
export * from "./ItemForm.ts?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "88e4e232",
  null
  
)

export default component.exports
import { RouteConfig } from 'vue-router';
import MainLayout from '../app/layouts/Main/MainLayout.vue';

export const settingsRoutes: RouteConfig[] = [
  {
    path: '/settings',
    component: MainLayout,
    meta: { title: 'Settings', roles: ['admin'], order: 3 },
    children: [
      {
        path: 'app',
        name: 'settings-app',
        meta: { title: 'App settings' },
        component: () =>
          import('./views/AppSettingsEditor/AppSettingsEditor.vue'),
      },
      {
        path: 'local',
        name: 'settings-local',
        meta: { title: 'Local settings' },
        component: () =>
          import('./views/LocalSettingsEditor/LocalSettingsEditor.vue'),
      },
      {
        path: 'oauth2',
        name: 'settings-oauth2',
        meta: { title: 'Oauth2 settings' },
        component: () =>
          import('./views/Oauth2SettingsEditor/Oauth2SettingsEditor.vue'),
      },
    ],
  },
];

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "v-navigation-drawer",
        {
          attrs: { clipped: "", app: "" },
          model: {
            value: _vm.drawer,
            callback: function ($$v) {
              _vm.drawer = $$v
            },
            expression: "drawer",
          },
        },
        [
          _c("nav-tree", {
            staticClass: "main-menu my-3 nav-menu",
            attrs: { routes: _vm.routes },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-app-bar",
        { attrs: { app: "", color: "#505151", "clipped-left": "", dark: "" } },
        [
          _c("v-app-bar-nav-icon", {
            on: {
              click: function ($event) {
                _vm.drawer = !_vm.drawer
              },
            },
          }),
          _vm._v(" "),
          _c("span", [
            _c("img", {
              attrs: { width: "30", height: "30", src: _vm.icons.logo },
            }),
          ]),
          _vm._v(" "),
          _c(
            "v-toolbar-title",
            {
              staticStyle: { cursor: "pointer" },
              on: {
                click: function ($event) {
                  return _vm.$router.push("/")
                },
              },
            },
            [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.$t("Providing information to related systems")) +
                  "\n      "
              ),
            ]
          ),
          _vm._v(" "),
          _c("v-spacer"),
          _vm._v(" "),
          _c("profile-menu"),
          _vm._v(" "),
          _c(
            "v-tooltip",
            {
              attrs: { bottom: "" },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function ({ on, attrs }) {
                    return [
                      _c(
                        "v-btn",
                        _vm._g(
                          _vm._b(
                            { attrs: { icon: "" }, on: { click: _vm.logout } },
                            "v-btn",
                            attrs,
                            false
                          ),
                          on
                        ),
                        [_c("v-icon", [_vm._v(_vm._s(_vm.icons.logout))])],
                        1
                      ),
                    ]
                  },
                },
              ]),
            },
            [_vm._v(" "), _c("span", [_vm._v(_vm._s(_vm.$t("logout")))])]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-main",
        { staticClass: "main-layout" },
        [
          _c(
            "v-container",
            { staticClass: "main-container pa-8" },
            [_c("router-view", { key: _vm.key })],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import {
  VuexModule,
  Module,
  getModule,
  MutationAction,
} from 'vuex-module-decorators';
import defaultSettings from '../../settings';

import store from '.';

const {
  title,
  footer,
  sidebarLogo,
  onPrem,
  chunkSize,
  version,
  baseUrl: baseURL,
  defaultLanguage,
  fallbackLanguage,
  languages,
} = defaultSettings;

@Module({ dynamic: true, store, name: 'app' })
export class App extends VuexModule {
  loading = false;

  title = title;
  footer = footer;
  sidebarLogo = sidebarLogo;
  onPrem = onPrem;
  chunkSize = chunkSize;
  version = version;
  baseUrl = baseURL;
  defaultLanguage = defaultLanguage;
  fallbackLanguage = fallbackLanguage;
  languages = languages;

  @MutationAction({ mutate: ['loading'] })
  async setLoading(loading: boolean): Promise<{ loading: boolean }> {
    return { loading };
  }
}

export const appModule = getModule(App);

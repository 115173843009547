import { Route, RouteConfig } from 'vue-router';
import MainLayout from '../app/layouts/Main/MainLayout.vue';
import { cacheApi } from './services/CacheApi';

let cacheTitleAbortController: AbortController | null = null;

const abortCacheTitle = () => {
  if (cacheTitleAbortController) {
    cacheTitleAbortController.abort();
  }
  cacheTitleAbortController = null;
}

export const cacheRoutes: RouteConfig[] = [
  {
    path: '/',
    component: MainLayout,
    meta: {
      title: 'TileCache',
    },
    children: [
      {
        path: '/',
        name: 'cache-list',
        meta: { title: 'TileCacheList', order: 1 },
        component: () => import('./views/CacheList/CacheList.vue'),
      },
      {
        path: 'cache/:id',
        props: true,
        name: 'cache',
        meta: {
          hidden: true,
          navName: 'cache-list',
          getTitle: async (route: Route) => {
            abortCacheTitle();
            const id = route.params.id;
            if (id === undefined) {
              return 'cache';
            }
            cacheTitleAbortController = new AbortController();
            const cache = await cacheApi.getOne(id, {
              signal: cacheTitleAbortController.signal,
            });
            return cache.name;
          },
        },
        component: () => import('./views/CacheItem/CacheItem.vue'),
      },
    ],
  },
  {
    path: '/seeding',
    component: MainLayout,
    meta: { title: 'TileCacheSeedingTasks' },
    children: [
      {
        path: 'tasks',
        meta: { title: 'TileCacheSeedingTasks', order: 1 },
        name: 'seeding_tasks',
        component: () => import('./views/SeedingTasks/SeedingTasks.vue'),
      },
    ],
  },
];

import { RouteConfig } from 'vue-router';
import MainLayout from '../app/layouts/Main/MainLayout.vue';

export const userRouters: RouteConfig[] = [
  {
    path: '/profile',
    component: MainLayout,
    meta: {
      title: 'Profile',
      hidden: true,
    },
    children: [
      {
        path: '/',
        name: 'profile-settings',
        meta: { title: 'Profile', order: 3 },
        component: () => import('./views/Profile/Profile.vue'),
      },
    ],
  },
  {
    path: '/users',
    component: MainLayout,
    meta: {
      title: 'Users',
      roles: ['admin'],
    },
    children: [
      {
        path: '/',
        name: 'users-list',
        meta: { title: 'UserList', order: 2 },
        component: () => import('./views/UserList/UserList.vue'),
      },
      {
        path: 'new',
        name: 'new_user',
        meta: { title: 'UserCreate', hidden: true },
        component: () => import('./views/UserEdit/UserEdit.vue'),
      },
      {
        path: 'edit/:id',
        props: true,
        name: 'edit_user',
        meta: { hidden: true },
        component: () => import('./views/UserEdit/UserEdit.vue'),
      },
      {
        path: ':id',
        props: true,
        name: 'user',
        meta: { hidden: true },
        component: () => import('./views/UserItem/UserItem.vue'),
      },
    ],
  },
];

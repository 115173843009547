import Vue from 'vue';
import router from './app/router';
import store from './app/store';

import { i18n } from './plugins/i18n';
import vuetify from './plugins/vuetify';

import { NoticeSetup } from '@nextgis_misc/vuetify/notice';

Vue.use(NoticeSetup, {
  vuetify,
  confirmDialog: () => ({
    buttonTrueText: i18n.t('yes'),
    buttonFalseText: i18n.t('no'),
  }),
});

import App from './app/App/App.vue';

Vue.config.productionTip = false;

export const app = new Vue({
  vuetify,
  i18n,

  el: '#app',
  router,
  store,
  render: (h) => h(App),
});
